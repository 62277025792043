<template>
  <ba-container>
    <ba-toolbar
      :toolbarProps="toolbarProps"
      @add="addContact()"
    />
    <ba-filter
      :placeholder="$t('ba-library.filter.search')"
      :position="'end'"
      :cols="3"
      :value="value"
      @input="searchData"
    />
    <ba-table
      :tableProps="tableProps"
      :loader="loader"
      :params="params"
      @reload="getContacts"
      @selected="setContact"
      :pagination="true"
    />
  </ba-container>
</template>

<script>
import BaContainer from './../../../components/Container.vue'
import BaToolbar from './../../../components/Toolbar.vue'
import BaTable from './../../../components/Table.vue'
import BaFliter from './../../../components/Filter.vue'
import Headers from './tableHeaders'
import i18n from '@/plugins/i18n'

export default {
  components: {
    'ba-container': BaContainer,
    'ba-toolbar': BaToolbar,
    'ba-table': BaTable,
    'ba-filter': BaFliter
  },
  data () {
    return {
      value: this.$route.query.contact,
      tableProps: {
        items: [],
        headers: Headers.contactsList,
        type: 'contact'
      },
      toolbarProps: {
        title: 'BÚSQUEDA DE CONTACTO',
        actions: [
          { type: 'add', icon: 'mdi-plus', route: 'contact', color: 'teal', label: i18n.t('contact.components.toolbar.createButton') }
        ]
      },
      params: {
        pagina: 1,
        cantidad: 10,
        filtrobuscar: '',
        cantPages: 1
      },
      loader: false
    }
  },
  methods: {
    searchData (value) {
      this.params.filtrobuscar = value
      this.getContacts(this.params)
    },
    getContacts (params, scrollable) {
      if (scrollable) {
        this.loader = true
      }
      this.$store.dispatch('loader/pending')
      this.$store.dispatch('contact/getAllContacts', params)
        .then((contactResponse) => {
          this.$store.dispatch('loader/done')
          if (scrollable) {
            this.tableProps.items.push(...contactResponse.data)
          } else {
            this.params.pagina = 1
            this.tableProps.items = contactResponse.data
            this.params.cantPages = contactResponse.pagination.cantPages
          }
          this.loader = false
        })
        .catch((err) => {
          this.$store.dispatch('loader/done')
          console.log(err)
        })
    },
    setContact (data) {
      this.$router.push({ name: 'externalContact', query: { contactId: data.id } })
    },
    addContact () {
      this.$router.push({ name: 'externalContact' })
    }
  }
}
</script>

<style>
</style>
