import i18n from '@/plugins/i18n'

const contactsList = [
  {
    label: i18n.t('contact.components.basicInfo.identifier'),
    value: 'identificador',
    icon: 'mdi-domain',
    visible: true
  },
  {
    label: i18n.t('contact.components.basicInfo.name'),
    value: 'nombre',
    icon: '',
    visible: false
  },
  {
    label: i18n.t('contact.components.basicInfo.lastName'),
    value: 'apellido',
    icon: '',
    visible: false
  },
  {
    label: i18n.t('contact.components.basicInfo.email'),
    value: 'email',
    icon: 'mdi-email',
    visible: true
  }
  /* {
    text: i18n.t('contact.components.basicInfo.identificador'),
    value: 'identificador'
  },
  {
    text: i18n.t('contact.components.basicInfo.name'),
    value: 'nombre'
  },
  {
    text: i18n.t('contact.components.basicInfo.lastName'),
    value: 'apellido'
  },
  {
    text: i18n.t('contact.components.basicInfo.email'),
    value: 'email'
  },
  {
    text: 'Acciones',
    value: 'acciones',
    sortable: false
  } */
]

export default {
  contactsList
}
